import React from "react";
import ServicesSection from "./ServicesSection";

const DesktopServices = ({ serviceData }) => {
  return (
    <ServicesSection
      serviceData={serviceData}
      className="max-w-[80rem] m-auto h-auto  rounded-xl  py-[2rem] "
      imageScaleFactor={1.5}
    />
  );
};

export default DesktopServices;
