import { AnimatePresence, motion } from "framer-motion";
import Grid from "@mui/material/Grid";
import { Fragment } from "react";

import ServiceItemSanity from "./ServiceItemSanity";
import { useInView } from "react-intersection-observer";
import { imageScale } from "@/utils/motion";
import { useMediaQuery } from "@mui/material";
import { GilroyFontDark, GilroyFontLight } from "@lib/GilroyFont";
import HeadLine2 from "./../HeadLine2";

const ServicesSection = ({ className, imageScaleFactor, serviceData }) => {
  const [ref, inView] = useInView({
    triggerOnce: true, // This means the animation will trigger only once when it comes into view
    threshold: 1, // Percentage of the component's visibility, adjust as needed
  });

  // const [onScreen, setOnScreen] = useState(false);
  // UseAnimationIos(ref, setOnScreen);
  const animation = inView
    ? imageScale(imageScaleFactor).animate
    : imageScale(0.5).initial;
  const isMobile = useMediaQuery("(max-width:786px)");

  return (
    <div>
      <div
        // initial={imageScale(0.5).initial} animate={animation}
        className={className}
      >
        <div className="flex flex-col pl-mobile">
          <div className=" relative flex  justify-start ">
            <h1
              className={` ${GilroyFontDark.className} heading-text-services`}
            >
              {" "}
              {serviceData?.services_main_heading}
            </h1>
            <span className="border-b-[5px] absolute md:w-10 w-[1.5rem] border-[#ae679a] md:top-[6rem] top-[2.2rem] left-4" />
            <span className="border-b-[5px] absolute md:w-[14rem] w-[8.5rem] border-[#ae679a] md:top-[6rem] top-[2.2rem] left-[6rem]" />
          </div>

          <h2 className="heading-text-services-new">
            {serviceData?.services_h2_heading}
          </h2>
          <p
            className={` ${GilroyFontLight.className} paragraph-text-services-offer mb-2 p-home `}
          >
            {serviceData?.services_paragraph}
          </p>
        </div>

        <div ref={ref} />

        {inView && (
          <AnimatePresence>
            <motion.div initial={imageScale(0.5).initial} animate={animation}>
              <Grid
                sx={{
                  marginTop: 1,
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
                container
                spacing={0}
              >
                {!serviceData?.hero_content ? (
                  <h1>loading..</h1>
                ) : (
                  serviceData.hero_content.map((service, index) => (
                    <Fragment key={index}>
                      {service?.hide && isMobile ? (
                        ""
                      ) : (
                        <ServiceItemSanity service={service} key={service.id} />
                      )}
                    </Fragment>
                  ))
                )}
              </Grid>
            </motion.div>
          </AnimatePresence>
        )}

        <HeadLine2 />
      </div>
    </div>
  );
};

export default ServicesSection;
