import React from "react";

import localFont from "@next/font/local";

const Gilroy = localFont({
  src: [
    {
      path: "../../public/assets/fonts/gilroy/Gilroy-Bold.ttf",
      weight: "400",
    },
    {
      path: "../../public/assets/fonts/gilroy/Gilroy-Black.ttf",
      weight: "700",
    },
  ],
  variable: "--font-gilroy",
});

const HeadLine2 = () => {
  return (
    <div
      style={{
        transform: "rotate(2.5deg)",
        paddingTop: "20px",
        paddingBottom: "10px",
      }}
      className={`headline-area pt-90 rpt-85 rel z-1 pb-20 pt-10 sm:pb-0 sm:pt-0  ${Gilroy.className} `}
    >
      <div className="container-fluid">
        <div
          className="headline-text-2 marquee"
          // dangerouslySetInnerHTML={{ __html: data }}
        >
          <span>
            TOP RATED &nbsp;
            <span>Design </span> Agency IN <span> USA </span>
          </span>
        </div>
      </div>

      <style jsx global>{`
        .headline-wrap > span .marquee-item i,
        .tab-style-one .nav-item:not(:last-child) {
          margin-right: 40px;
        }
        @-webkit-keyframes marquee {
          100% {
            -webkit-transform: translate(-100%, 0);
            transform: translate(-100%, 0);
          }
        }
        @keyframes marquee {
          100% {
            -webkit-transform: translate(-100%, 0);
            transform: translate(-100%, 0);
          }
        }
        @-webkit-keyframes marquee_right {
          from {
            -webkit-transform: translate3d(-100%, 0, 0);
            transform: translate3d(-100%, 0, 0);
          }
          to {
            -webkit-transform: translate3d(0, 0, 0);
            transform: translate3d(0, 0, 0);
          }
        }
        @keyframes marquee_right {
          from {
            -webkit-transform: translate3d(-100%, 0, 0);
            transform: translate3d(-100%, 0, 0);
          }
          to {
            -webkit-transform: translate3d(0, 0, 0);
            transform: translate3d(0, 0, 0);
          }
        }
        @-webkit-keyframes marquee_left {
          from {
            -webkit-transform: translate3d(0, 0, 0);
            transform: translate3d(0, 0, 0);
          }
          to {
            -webkit-transform: translate3d(-100%, 0, 0);
            transform: translate3d(-100%, 0, 0);
          }
        }
        @keyframes marquee_left {
          from {
            -webkit-transform: translate3d(0, 0, 0);
            transform: translate3d(0, 0, 0);
          }
          to {
            -webkit-transform: translate3d(-100%, 0, 0);
            transform: translate3d(-100%, 0, 0);
          }
        }
        .hero-content h1 span,
        .hero-four-content h1 .marquee-inner span {
          color: transparent;
          -webkit-text-stroke: 1px;
          -webkit-text-stroke-color: white;
        }
        .headline-text > span {
          padding-left: 100%;
          -webkit-animation: 15s linear infinite marquee;
          animation: 30s linear infinite marquee;
        }
        .headline-wrap,
        .marquee-wrap {
          display: -webkit-box;
          display: -ms-flexbox;
          display: flex;
          overflow: hidden;
          white-space: nowrap;
        }
        .headline-wrap > span {
          -webkit-animation: 15s linear infinite marquee;
          animation: 15s linear infinite marquee;
        }
        .headline-wrap > span .marquee-item {
          margin-right: 50px;
          line-height: 1.2;
          font-size: 65px;
          font-weight: 300;
          color: var(--tech-dark-color);
        }
        @media only screen and (max-width: 1399px) {
          .headline-wrap > span .marquee-item {
            font-size: 55px;
          }
        }
        @media only screen and (max-width: 1199px) {
          .headline-wrap > span .marquee-item {
            font-size: 48px;
          }
        }
        .headline-wrap-two .marquee-wrap {
          margin-top: 0;
        }
        .headline-wrap-two .marquee-item {
          margin-right: 60px;
          line-height: 1.2;
          font-size: 30px;
          font-weight: 300;
          color: var(--tech-dark-color);
          display: -webkit-inline-box;
          display: -ms-inline-flexbox;
          display: inline-flex;
          -webkit-box-align: center;
          -ms-flex-align: center;
          align-items: center;
        }
        .headline-wrap-two .marquee-item i {
          font-size: 0.8em;
          margin-right: 30px;
        }
        .headline-wrap-two .marquee-item b,
        .review-form .form-group .form-control .current,
        .review-form .form-group .nice-select .current,
        .testimonial-five-right .author-speech {
          font-weight: 400;
        }
        .marquee-inner {
          padding-right: 50px;
        }
        .marquee-inner.one {
          -webkit-animation: 32s linear infinite marquee_left;
          animation: 32s linear infinite marquee_left;
        }
        .marquee-inner.two {
          -webkit-animation: 24s linear infinite marquee_right;
          animation: 24s linear infinite marquee_right;
        }
        .marquee-inner.three {
          -webkit-animation: 48s linear infinite marquee_left;
          animation: 48s linear infinite marquee_left;
        }
        .marquee-inner.left {
          -webkit-animation: 20s linear infinite marquee_left;
          animation: 20s linear infinite marquee_left;
        }
        .marquee-inner.right {
          -webkit-animation: 20s linear infinite marquee_right;
          animation: 20s linear infinite marquee_right;
        }
        @media only screen and (max-width: 1199px) {
          .headline-wrap-two .marquee-item {
            font-size: 25px;
            margin-right: 35px;
          }
          .headline-wrap-two .marquee-item i {
            margin-right: 15px;
          }
          .awards-tabs .nav-link .left .h3 {
            font-size: 25px;
          }
          .work-with-content {
            padding: 100px;
          }
        }
        @media only screen and (max-width: 991px) {
          .headline-wrap > span .marquee-item {
            font-size: 40px;
          }
          .headline-area.move-top {
            margin-top: -90px;
          }
          .work-with-content {
            padding: 50px;
          }
          .blog-meta li {
            font-size: 15px;
          }
        }
        @media only screen and (max-width: 767px) {
          .marquee-inner {
            padding-right: 15px;
          }
          .headline-area.move-top {
            margin-top: -50px;
          }
          .banner-inner h2 {
            font-size: 40px;
            margin-bottom: 10px;
          }
          .service-item {
            margin-top: 0;
          }
          .why-choose-right.style-two .why-choose-border-shape {
            right: -5%;
          }
        }
        @media only screen and (max-width: 575px) {
          .headline-wrap > span .marquee-item {
            font-size: 35px;
          }
          .headline-wrap-two .marquee-item {
            font-size: 20px;
            margin-right: 25px;
          }
          .headline-wrap-two .marquee-item i {
            margin-right: 10px;
          }
        }
        @media only screen and (min-width: 1400px) {
          .marquee-wrap {
            margin-top: -20px;
          }
          .error-image {
            margin-right: -150px;
          }
          .footer-right-content {
            border-left: 1px solid var(--tech-border-color);
            padding-left: 60px;
            padding-top: 75px;
            padding-bottom: 75px;
          }
        }
        @media only screen and (max-width: 375px) {
          .headline-wrap > span .marquee-item,
          .project-item .content h2,
          .team-profile-content h2 {
            font-size: 30px;
          }
        }
        .headline-text {
          line-height: 1;
          font-size: 250px;
          color: black;
          text-transform: uppercase;
          display: flex;
          overflow: hidden;
          width: 100%;
          white-space: nowrap;
        }
        .headline-text > span {
          padding-left: 100%;
          animation: marquee 30s linear infinite;
        }
        .headline-text > span span {
          opacity: 1;
          color: transparent;
          -webkit-text-stroke: 2px;
          -webkit-text-stroke-color: #9a5784;
        }

        .headline-text-2 {
          line-height: 1;
          font-size: 48px;
          color: white;
          text-transform: uppercase;
          display: flex;
          overflow: hidden;
          width: 100%;
          white-space: nowrap;
        }
        .headline-text-2 > span {
          padding-left: 100%;
          -webkit-animation: 15s linear infinite marquee;
          animation: 30s linear infinite marquee;
        }
        .headline-text-2 > span {
          padding-left: 100%;
          -webkit-animation: 15s linear infinite marquee;
          animation: 30s linear infinite marquee;
        }
        .headline-text-2 > span span {
          opacity: 1;
          color: transparent;
          -webkit-text-stroke: 2px;
          -webkit-text-stroke-color: white;
        }
      `}</style>
    </div>
  );
};

export default HeadLine2;
