import Grid from "@mui/material/Grid";
import Image from "next/image";

const ServiceItemSanity = ({ service }) => {
  return (
    <Grid
      //onClick={() => window.open(service.service_url, "_blank")}
      key={service.id}
      sm={6}
      md={4}
      item
      xs={12}
      lg={3}
      sx={{ display: "flex", alignItems: "center", justifyContent: "center" }}
    >
       <a
    href={service.service_url}
    target="_blank"
    rel="noopener noreferrer"
    style={{ textDecoration: "none", width: "100%", height: "100%", display: "flex", alignItems: "center", justifyContent: "center" }}
  >
      <div
        whilehover={{ scale: 1.1 }}
        className="bg-white z-[10]  my-3  min-w-[270px] max-w-[270px] min-h-[340px] max-h-[340px] mx-2 flex flex-col  items-center cursor-pointer rounded-2xl text-black"
      >
        <div className="max-h-[120px] min-h-[120px] pt-5">
          <Image
            priority="true"
            height={100}
            width={100}
            src={service.image_icon}
            alt={service?.image_alt}
            blurDataURL={service.image_icon}
            placeholder="blur" // Optional blur-up while loading
          />
        </div>
        <div className="flex flex-col pt-5 justify-start  text-center min-h-[140px] max-h-[140px] min-w-[270px] max-w-[270px] ">
          <p
            style={{ fontWeight: 500 }}
            className="text-center text-[5vh] sm:text-[1rem] py-2  text-black text-sm "
          >
            {service.services_heading}
          </p>
          <p
            className="text-black px-5"
            dangerouslySetInnerHTML={{ __html: service.services_paragraph }}
          ></p>
        </div>
        <span className="flex justify-center relative items-center -ml-2 py-2">
          <p className="pt-5 text-[17px] text-[#533F87] relative">
            Read More
            {/* eslint-disable-next-line */}
            <img
              width={20}
              height={20}
              alt="arrow vector icon up purple color"
              className="h-4 w-4 absolute top-[23px] -right-6"
              src="https://imagedelivery.net/9otR1sBY_9ZFhJikSNrX6g/80b43733-9c9f-4468-fcde-12ff5d5f2900/public"
            />
          </p>
        </span>
      </div>
      </a>
    </Grid>
  );
};

export default ServiceItemSanity;
